<template>
  <div>
    <div class="center" v-if="showLoading">
		<lottie :options="defaultOptionsSplashScreen" :height="300" :width="300" v-on:animCreated="handleAnimationSplashScreen"/>
	</div>
     <div class="home" v-else>
        <headerSection></headerSection> 
        <main class="mqbg">
            <div class="gl-content">
                <!-- start top bookmark -->
                <div id="here_top">&nbsp;</div>
                <!-- end top bookmark -->
            </div>
			<!-- start content -->
            <div id="contact_gl" class="section" :style="{ 'background-color': `#141D26`}">
                <div class="container">
                    <div class="lottiescan">
                        <lottie :options="defaultOptionsScan" :height="300" :width="300" v-on:animCreated="handleAnimationScan"/>
                    </div>
                    <div style="position: absolute; bottom: 70px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;">
                        <lottie :options="defaultOptionsScan2" :height="300" :width="300" v-on:animCreated="handleAnimationScan2"/>
                    </div>
                    <div class="errorscan" v-if="error">
						          <label class="d-flex justify-content-center" style="-webkit-text-stroke-width: 1px;-webkit-text-stroke-color: #ff5733;-webkit-text-fill-color: #fff;">{{error}}</label>
				            </div>
                    <div class="buttonscan">
                      <button class="bubbly-button" @click="scan">Scan!</button>
                    </div>
                    <div class="form__group field inputscan">
                        <input type="input" class="form__field" v-model="url" placeholder="กรอกลิงค์เกม" required />
                        <label for="name" class="form__label">กรอกลิงค์เกม</label>
                    </div>
                </div>
            </div>
	      </main>
        

        <footerSection></footerSection>
      </div>
  </div>
</template>
<script>
import firebase, { database } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import _ from 'lodash'
// const axios = require("axios").default;
import moment from 'moment-timezone'
import router from './../router'
import Lottie from './lottie.vue'
import * as animateSantaWalk from '../assets/hackslot.json'
import * as animateScan from '../assets/scan.json'
import * as animateScan2 from '../assets/robot.json'
require('vue2-animate/dist/vue2-animate.min.css')
// @ is an alias to /src
export default {
  name: 'Huay',
  created () {
    const user = localStorage.getItem('userData')
	  if (user) {
		  const _user = JSON.parse(user)
		  this.$store.dispatch('user/setUser', _user).then(() => {
        this.getGames()
      }).catch(err => { console.error(err) })
	  } else {
      firebase.auth().signOut()
      localStorage.removeItem('userData')
      router.replace('/')
	  }
    document.body.style.backgroundColor = '#141D26'
    this.defaultOptionsSplashScreen = { animationData: animateSantaWalk.default, autoplay: false, loop: true }
    this.defaultOptionsScan = { animationData: animateScan.default, autoplay: false, loop: true }
    this.defaultOptionsScan2 = { animationData: animateScan2.default, autoplay: false, loop: true }
  },
  mounted () {
    if (!firebase.auth().currentUser) {
      router.replace('/')
    } else {
      this.animSplashScreen.play()
      setTimeout(() => {
        this.showLoading = false
        setTimeout(() => {
            this.animSplashScreen.stop()
            this.animScan.play()
            this.animScan2.play()
        }, 500)
      }, 3000)
    }
  },
  data () {
    return {
      error:'',
      url:'',
      logoLeft:'',
      logoRight:'',
      gameKey: 'hackslot',
      huays:[],
      rows:20,
      showLoading: true,
      pictureUrl: '',
      code: '',
      games_web: [],
      search: '',
      loading: false,
      web: [],
      point: 0,
      permission: [],
      defaultOptions: null,
      defaultOptions2: null,
      defaultOptionsSplashScreen: null,
      openGiftData: false,
      canOpen: false,
      percentage: 0,
      loadingtext:'น้องออโต้กำลังประมวลผลให้อยู่ค่ะ รอสักครู่',
      credit:'',
      save:false,
      QTY:0,
      countQTY:0,
      oldQTY:0,
      isDisabled:false,
    }
  },
  computed: {
    user () {
      return this.$store.state.user.user
    },
    sites_credit () {
      return this.$store.state.user.sites_credit
    }
  },
  components: {
    lottie: Lottie,
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  methods: {
    scan(){
      if(this.url){
        //ตรงนี้เช็ต ชึ้นต้นด้วย m.pgf ได้ไหม
        
        const games = ['m.y41w4.com','m.pgf-asqb7a.com','m.pgf-asw0zzz.com','m.pgf-asw0zz.com','gamessea.kaga88.com','sg.sp-platform.com','w1.bluecave88.com','frerpt.kathilag.com','static-prod-tw.dcgames.fun','static.shycfjwz.com','api.ayitang.com','gamessea.kaga88.com','cors.mannagaming.com','629xyss.fafafa3388.com','purple.romadeluxe.com','booongo2-dgm.gv-gamespace.com','lobby-ahvgl62wwq-df.a.run.app','wbgame.sssjlsss.com','gamelaunch.wazdan.com','wsx.mayousa.com','funky.ttstations.com','lobby.argonplay.com','games.ambslot.com','nttech-sg0.ppgames.net','game.3yjt.com','m.pgjazz.com','lobby.silverkirinplay.com','egame.multi888.com','play.pgslot.co','www.gwc688.net']
        const url = this.url.split('/');
        if((url[0]==='https:' || url[0]==='http:') && (games.includes(url[2]) || url[2].includes('m.pgf'))){ // 
          if(url[0]==='http:'){
            this.url = this.url.replace('http:','https:');
          }
          localStorage.setItem("url", this.url);
              this.$router.push('/hackslotsuccess');
            }else{
          this.error = 'ระบบ AI ไม่สามารถ scan ลืงค์เกมส์ของท่านได้';
        }
      }
    },
    selectNumber(num){
        if(this.huays[num]){
            this.huays[num] = false;
            this.countQTY--;
        }else if(this.QTY > this.countQTY){
            this.huays[num] = true;
            this.countQTY++;
        }
        this.rows++;
        this.rows--;
    },
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    handleAnimationSplashScreen: function (anim) {
      this.animSplashScreen = anim
    },
    handleAnimationScan: function (anim) {
        this.animScan = anim
    },
    handleAnimationScan2: function (anim) {
        this.animScan2 = anim
    },
    renderNumber(row,i){
     const num = ((row-1)*5)+i;
     if(num.toString().length == 1){
         return '0'+num;
     }else{
         return num.toString();
     }
    },
    async getGames () {
      this.games_web = (await firebase.database().ref(`/games_web/hackslot`).once('value')).val()
      if(this.games_web.status){
        if(this.user && this.user.games && this.user.games.hackslot){
          if(Number(this.user.games.hackslot.expiredate) <= moment().tz('Asia/Bangkok').format('YYYYMMDDHHmmss')){
            this.$swal({ icon: 'error', title: 'Error!', text: 'ท่านไม่มีสิทธิ์ในการใช้สูตร โปรดติดต่อแอดมิน!!!' })
            router.replace('/event')
          }
        }else{
          this.$swal({ icon: 'error', title: 'Error!', text: 'ท่านไม่มีสิทธิ์ในการใช้สูตร โปรดติดต่อแอดมิน!!!' })
          router.replace('/event')
        }
      }else{
        this.$swal({ icon: 'error', title: 'Error!', text: 'ท่านไม่มีสิทธิ์ในการใช้สูตร โปรดติดต่อแอดมิน!!!' })
        router.replace('/event')
      }
    }
  }
}
</script>
<style scoped>
.center {
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#contact_gl {
  min-height: 600px;
  color: #333;
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
	/*background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #d1d1d1 100%);
	background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%,#d1d1d1 100%);
	background: radial-gradient(ellipse at center, #f7f7f7 0%,#d1d1d1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#d1d1d1',GradientType=1 );*/
}
#contact_gl::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
}
.boxlogin {
    background: #fff;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 0 0 40px #000;
    color: #8b8c8d;
    font-size: 0;
}
.boxlogin .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 100%;
}
.boxlogin, .box .box-part {
    box-sizing: border-box;
}
.boxlogin .partition {
    width: 100%;
    height: 100%;
}
.boxlogin .partition .partition-title {
    box-sizing: border-box;
    padding: 30px;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    font-weight: 300;
}
.boxlogin .partition .partition-form {
    padding: 0 20px;
    box-sizing: border-box;
}
.boxlogin .button-set {
    margin-bottom: 8px;
}
.boxlogin .github-btn {
    border-color: #dba226;
    color: #dba226;
}
.boxlogin .large-btn {
    width: 100%;
    background: #fff;
}
.boxlogin button {
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    letter-spacing: 1px;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
    min-width: 140px;
    margin-top: 8px;
    color: #8b8c8d;
    cursor: pointer;
    border: 1px solid #dddedf;
    text-transform: uppercase;
    transition: all .1s;
    font-size: 10px;
    outline: none;
}
 .p2 {
	 letter-spacing: 0;
	 text-shadow: 0 0 80px rgba(255, 255, 255, .5);
	/* Clip Background Image */
	 background: url(https://i.ibb.co/RDTnNrT/animated-text-fill.png) repeat-y;
	 -webkit-background-clip: text;
	 background-clip: text;
	/* Animate Background Image */
	 -webkit-text-fill-color: transparent;
	 -webkit-animation: aitf 80s linear infinite;
	/* Activate hardware acceleration for smoother animations */
	 -webkit-transform: translate3d(0, 0, 0);
	 -webkit-backface-visibility: hidden;
}
a.button1{
    display:inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    text-align:center;
    transition: all 0.2s;
    text-decoration: none;
    font-size: 18px;
    width: 70px;
}
.active{
    color:#000000 !important;
    background-color:#FFFFFF !important;
    text-decoration: none !important;
}
div.rowClass {
    flex-direction: row;
    justify-content: space-around;
    display: flex;
}
@media all and (max-width:30em){
    a.button1{
        margin:0.4em auto;
    }
}
.buttonscan {
/*  position: absolute; top: 250px; align-items: center; justify-content: center; display: flex; left: 0; right: 0; */
    position: absolute;
    bottom: 400px;
    align-items: flex-end;
    justify-content: center;
    display: flex;
    left: 0;
    right: 0;
}
.inputscan {
  position: absolute; top: 200px;align-items: center; justify-content: center;
}
.lottiescan {
  position: absolute; top: 100px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;
}
.errorscan {
  position: absolute; top: 150px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;
}
@media (max-height: 768px) {
  .inputscan {
    position: absolute; top: 100px;align-items: center; justify-content: center;
  }
  .lottiescan {
    position: absolute; top: 50px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;
  }
  /*
  .buttonscan {
    position: absolute; top: 150px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;
  }
  */
  .errorscan {
    position: absolute; top: 90px; align-items: center; justify-content: center; display: flex; left: 0; right: 0;
  }
}
.btn2 {
  width: 100%;
    font-size: 18px;
    border-radius: 25px;
    color: #fff;
    border: none;
    padding: .5rem 1.5rem;
    min-width: 180px;
    padding: .85rem 2.5rem;
    background: linear-gradient(
180deg
,#91f27f 10%,#00b302);
    box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 1px 2px hsl(0deg 7% 92% / 24%);
}
.md-title {
    color:#fff;
    padding-top: 15px;
}
/* Animate Background Image */
 @-webkit-keyframes aitf {
	 0% {
		 background-position: 0% 50%;
	}
	 100% {
		 background-position: 100% 50%;
	}
}
</style>
<style lang="scss" scoped>
$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #fff;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 90%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight:700;    
  }
  padding-bottom: 6px;  
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary,$secondary);
  border-image-slice: 1;
}
/* reset input */
.form__field{
  &:required,&:invalid { box-shadow:none; max-width: 1150px;}
}
/* demo */
$fuschia: #2c4054;
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

body{
  font-size: 16px;
  font-family: 'Helvetica', 'Arial', sans-serif;
  text-align: center;
  background-color: $baby-blue;
}
.bubbly-button{
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em 2em;
 // margin-top: 100px;
 // margin-bottom: 60px;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.5);
  
  &:focus {
    outline: 0;
  }
  
  &:before, &:after{
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  
  &:before{
    display: none;
    top: -75%;
    background-image:  
      radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 20%, $button-bg 20%, transparent 30%),
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
  //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }
  
  &:after{
    display: none;
    bottom: -75%;
    background-image:  
    radial-gradient(circle, $button-bg 20%, transparent 20%), 
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%),
    radial-gradient(circle, $button-bg 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
  //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
 
  &:active{
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }
  
  &.animate{
    &:before{
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after{
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}


@keyframes topBubbles {
  0%{
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
 100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
 100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
  background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}
</style>